import React from "react";
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { withPreview } from 'gatsby-source-prismic'

import Image from 'components/Global/Image';
import SEO from 'components/Global/SEO';
import CTARoute from 'components/Home/CTARoute';
import Oefening from 'components/Route/Oefening';
import CardRoute from 'components/Routes/CardRoute';

import styles from './Route.module.scss';

const RouteTemplate = ({data}) => {

  const route = data.route.data;
  const routes = data.routes.nodes;

  return (
    <div>
      <SEO title={route.titel.text} description={route.titel.text} />
      <CTARoute
        title={route.titel.text}
        subtitle={route.subtitel.text}
        text={route.tekst.text}
        image={route.afbeelding}
      />

      <div className="container d-flex justify-content-between flex-column flex-sm-row mt-5 mb-3">
        <div>
          <h2 className="text-primary mb-0">
            Oefeningen
            <span className="text-primary h4 d-block">Op deze route</span>
          </h2>
        </div>
        <div>
          <Link to="/oefeningen" className="btn btn-outline-secondary">Bekijk alle oefeningen</Link>
        </div>
      </div>

      <div className="container">
        <ol className={`list-unstyled ${styles.oefeningenlijst}`}>
        {route.oefeningen.map((item, key) => (
          <li key={key} className="mb-2">
            <Oefening 
              number={key + 1}
              title={item.oefening.document.data.titel.text}
            />
          </li>
        ))}
        </ol>
      </div>
      
      {route.route_omschrijving.html && (
        <div className="bg-blue-light my-5 py-4">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h2 className="text-primary mb-0">Route</h2>
                <div className="h4 text-primary">Omschrijving van deze route</div>
                <div 
                  className={styles.omschrijving}
                  dangerouslySetInnerHTML={{ __html: route.route_omschrijving.html }}
                ></div>
              </div>
              <div className="col-md-5 offset-1">
                <Image 
                  src={route.afbeelding}
                  alt={`${route.subtitel.text} ${route.titel.text}`}
                  className="my-2"
                />
              </div>
            </div>
          </div>
        </div>
      )}
      
      <div className="container my-5">
        
        <h2 className="text-primary mb-3">
          <span className="text-primary mb-0 h4 d-block">Meer</span>
          Routes
        </h2>
        <div className="row no-gutters">
          {routes.map((item, key) => {
            return (
              <div key={key} className="col-md-4">
                <CardRoute 
                  url={`/route/${item.uid}`}
                  image={item.data.afbeelding}
                  title={item.data.titel.text}
                  subtitle={item.data.subtitel.text}
                  variant={(key % 2 === 0 ? 'dark' : 'light') }
                />
              </div>
            )
          })}
        </div>
      </div>



    </div>
  );
}

export const query = graphql`
  query($id: String!) {
    route: prismicRoute(id: {eq: $id}) {
      data {
        titel {
          text
        }
        subtitel {
          text
        }
        tekst {
          text
        }
        afbeelding {
          localFile {
            url
            childImageSharp {
              fluid(maxWidth: 997) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        oefeningen {
          oefening {
            id
            document {
              ... on PrismicOefening {
                id
                data {
                  titel {
                    text
                  }
                }
              }
            }
          }
        }
        route_omschrijving {
          html
        }
      }
    }
    routes: allPrismicRoute(
      filter: {id: {ne: $id}}
      sort: {fields: data___subtitel___text, order: ASC}
      ) {
      nodes {
        id
        uid
        data {
          titel {
            text
          }
          subtitel {
            text
          }
          afbeelding {
            localFile {
              url
              childImageSharp {
                fluid(maxWidth: 997) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPreview(RouteTemplate);