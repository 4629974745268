import React from "react";
import { Link } from "gatsby"
import styles from './CardRoute.module.scss';
import Image from 'components/Global/Image';

const CardRoute = ({ url, image, title, subtitle, variant = "light" }) => {
  return (
    <Link to={url} className={styles.link}>
      <div className={`card ${styles.card} ${variant}`}>
        <div className={styles.wrapper}>
          <div className="h3">
            <span className="mb-0 h5 d-block text-lower">{subtitle}</span>
            {title}
          </div>
          <Image 
            src={image} 
            alt=""
            className="card-img" 
          />
        </div>
      </div>
    </Link>
  );
}

export default CardRoute;