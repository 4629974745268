import React from "react";
import styles from './Oefening.module.scss';

const Oefening = ({number, title}) => {
  return (
    <div className={styles.oefening}>
      <span className={styles.number}>{number}</span>
      {title}
    </div>
  )
}

export default Oefening;